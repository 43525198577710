:root {
  --grey-1: #f6f8fa;
  --white: #fff;
  --smoke: #eee;
  --color__primary: #00a3ff;
  --color__green: #009350;
  --color__red: #d81111;
  --color__black: #313544;
  --color__override: rgb(255, 154, 0);

  --shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.06);

  --space-base: 1.125rem;
  --font-main: "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
}

* {
  box-sizing: border-box;
}

body {
  color: var(--color__black);
  font-family: var(--font-main) !important;
}

input,
button,
select,
textarea {
  font-family: var(--font-main);
}

button {
  cursor: pointer;
}

.app {
  position: relative;
}

input[disabled] {
  opacity: 0.5;
}

.app__save-state {
  align-items: center;
  display: flex;
}

.project-dashboard__loading {
  align-items: center;
  background: #fff;
  bottom: 0;
  display: flex;
  font-size: 36px;
  justify-content: center;
  left: 0;
  padding: 20px;
  position: sticky;
}

.override-save {
  align-items: center;
  background-color: var(--color__red);
  border-radius: 6px;
  border: 0;
  box-shadow: 0 0;
  color: var(--white);
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  margin-right: 6px;
  padding: 3px 6px;
  margin-left: 9px;
}

.environment__bar {
  align-items: center;
  display: flex;
  font-size: 14px;
  justify-content: center;
  left: 0;
  overflow: hidden;
  position: absolute;
  text-align: center;
  top: 0;
  width: 100%;
  z-index: 2;
}

.environment__bar-text {
  background: var(--color__primary);
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  color: var(--white);
  text-align: center;
  width: 200px;
}

.environment-production .environment__bar-text {
  background: var(--color__primary);
}

.environment-local .environment__bar-text {
  background: var(--color__green);
}

.environment-staging .environment__bar-text {
  background: var(--color__red);
}

#imageUpload {
  display: none;
}

.app__canvas {
  background: var(--grey-1);
  height: 100vh;
  overflow: hidden;
  width: 100%;
}

.app__sidebar {
  background: var(--white);
  border-right: 1px solid var(--smoke);
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: space-between;
  left: 0;
  position: fixed;
  top: 0;
  width: 60px;
  z-index: 4;
}

.app__sidebar::-webkit-scrollbar {
  display: none;
}

.app__details {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  padding: 10px 10px 0 0;
  position: fixed;
  right: 0;
  top: 0;
}

.app__details-container {
  position: relative;
}

.details__header {
  width: 300px;
}

.card {
  background-color: var(--white);
  border: 1px solid var(--smoke);
  border-radius: 9px;
  box-shadow: var(--shadow);
}

.details__header {
  padding: var(--space-base);
}

.parts-list {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 110px - 36px);
  justify-content: space-between;
  margin-top: 10px;
  width: 300px;
}

.parts-list--expanded {
  width: 800px;
}

.parts-list--expanded .parts-list__footer-buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.parts-list--expanded .parts-list__button {
  margin-bottom: 0;
  margin-right: 9px;
}

.parts-list--expanded .parts-list__button:last-child {
  margin-right: 0;
}

.icon-button__icon {
  align-items: center;
  display: flex;
  height: 24px;
  justify-content: center;
  width: 24px;
}

.sidebar__icon-button {
  align-items: center;
  background: transparent;
  border: 0;
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  height: 42px;
  justify-content: center;
  margin: 0 auto;
  margin-bottom: 6px;
  position: relative;
  width: 42px;
}

.sidebar__tooltip {
  background-color: var(--color__black);
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.06);
  border-radius: 6px;
  color: var(--white);
  display: none;
  position: absolute;
  bottom: 3px;
  left: calc(42px + 8px);
  padding: 9px 16px;
  width: max-content;
  z-index: 4;
}

.sidebar__tooltip::before {
  content: "";
  display: block;
  width: 0;
  height: 0;
  position: absolute;

  border-bottom: 8px solid transparent;
  border-top: 8px solid transparent;
  border-right: 8px solid var(--color__black);
  left: -8px;

  top: 8px;
}

.canvas-totals {
  background: var(--white);
  bottom: 0;
  position: sticky;
  padding: 6px;
}

.item-list__canvas-total {
  align-items: center;
  border: 1px dashed #00a3ff;
  border-radius: 3px;
  color: var(--color__black);
  display: flex;
  font-size: 12px;
  justify-content: flex-end;
  letter-spacing: 0;
  line-height: 15px;
  padding: 6px;
}

.item-list__canvas-name {
  margin-right: 6px;
}

.sidebar__icon-button:hover .sidebar__tooltip {
  display: block;
}

.icon-button--save path,
.icon-button--save polygon {
  fill: var(--color__primary);
}

.sidebar__icon-button:last-child {
  margin-bottom: 0;
}

.sidebar__icon-button:hover,
.sidebar__icon-button:focus {
  background: #00a3ff1a;
  outline: 0;
}

.sidebar__icon-button.icon-button--active {
  background: var(--color__primary);
  outline: 0;
}

.sidebar__icon-button.icon-button--active .icon-button__icon path,
.sidebar__icon-button.icon-button--active .icon-button__icon polygon {
  fill: var(--white) !important;
}

.icon-button--save {
  border: 1px solid var(--smoke);
}

.icon-button--save:last-child {
  margin-bottom: 15px;
}

.app__sidebar-section {
  border-bottom: 1px solid var(--smoke);
  margin: 0 6px;
  padding: 15px 0;
}

.app__sidebar-header {
  align-items: center;
  border-bottom: 1px solid var(--smoke);
  display: flex;
  height: 60px;
  justify-content: center;
}

/* Shapes tool */
.shapes__selection {
  background-color: #ffffff;
  border: 1px solid #eeeeee;
  border-radius: 6px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.06);
  left: calc(60px + 10px);
  padding: 6px;
  position: absolute;
  top: 360px;
}

.shapes__selection .sidebar__icon-button {
  width: 30px;
  height: 30px;
  padding: 0;
}

.stairs__selection {
  background-color: #ffffff;
  border: 1px solid #eeeeee;
  border-radius: 6px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.06);
  left: calc(60px + 10px);
  padding: 6px;
  position: absolute;
  top: 260px;
}

.stairs__selection .sidebar__icon-button {
  width: 30px;
  height: 30px;
  padding: 0;
}

.edit-popup__label--stairs-lock {
  align-items: center;
  display: flex;
  margin-left: -6px !important;
}

.stairs-lock {
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 24px;
  justify-content: center;
  width: 24px;
}

.stairs-lock--locked {
  cursor: default;
}

.stairs-lock path {
  fill: var(--color__black);
  opacity: 0.1;
  transition: fill, opacity 200ms ease-in-out;
}

.edit-popup__control.save-calculation {
  justify-content: flex-end;
}

.stairs-lock:hover path {
  fill: var(--color__primary);
  opacity: 1;
}

.stairs-lock--locked path,
.stairs-lock--locked:hover path {
  fill: var(--color__black);
  opacity: 1;
  transition: none;
}

/* Details */
.details__header-image {
  display: block;
  height: auto;
  margin-right: 18px;
  width: 42px;
}

.details__header {
  align-items: center;
  display: flex;
}

.details__header-details {
  align-items: center;
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
}

.header__icon-button {
  align-items: center;
  background: transparent;
  border: 0;
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  height: 42px;
  justify-content: center;
  width: 42px;
}

.header__icon-button:hover {
  background: #00a3ff1a;
}

.header__icon-button:focus {
  background: var(--color__primary);
  outline: 0;
}

.header__icon-button:focus .icon-button__icon path,
.header__icon-button:focus .icon-button__icon polygon {
  fill: var(--white) !important;
}

.details__client-name {
  color: var(--color__black);
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 18px;
  margin: 0;
}

.details__project-id {
  opacity: 0.6;
  color: var(--color__black);
  font-size: 14px;
  letter-spacing: 0;
  line-height: 18px;
  margin: 0;
}

.parts-list__header {
  border-bottom: 1px solid var(--smoke);
  position: relative;
}

.parts-list__header-button {
  align-items: center;
  background: transparent;
  border: 0;
  display: flex;
  width: 100%;
  justify-content: space-between;
  outline: 0;
  padding: var(--space-base);
}

.grabrail-tools {
  margin-bottom: 8px;
  margin-right: 8px;
}

.parts-select--override {
  outline: 1px solid var(--color__override);
}

.grabrail-tools__group {
  align-items: flex-start;
  display: flex;
}

.fascia-bracket-options {
  max-width: 300px;
}

.ponywall-image {
  max-width: 116px;
  object-fit: cover;
  max-height: 74px;
}

.parts-list__heading {
  opacity: 0.8;
  color: var(--color__black);
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 18px;
  margin: 0;
}

.parts-list__header-button:focus .parts-list__heading,
.parts-list__header-button:hover .parts-list__heading {
  opacity: 1;
}

.parts-list__heading-icon {
  width: 24px;
}

.parts-list__expand-list {
  align-items: center;
  background-color: var(--white);
  border: 1px solid #00a3ff;
  border-radius: 50%;
  bottom: -12px;
  display: none;
  height: 24px;
  left: -12px;
  justify-content: center;
  margin: 0;
  padding: 0;
  position: absolute;
  width: 24px;
  z-index: 2;
}

.parts-list:hover .parts-list__expand-list {
  display: flex;
}

.parts-list__expand-icon {
  transform: rotate(0);
}

.parts-list--expanded .parts-list__expand-icon {
  transform: rotate(180deg);
}

.expand__tooltip {
  background-color: var(--color__black);
  box-shadow: 0 0;
  border-radius: 6px;
  bottom: -9px;
  color: var(--white);
  display: none;
  left: auto;
  padding: 9px 16px;
  position: absolute;
  transform: translateX(calc(-50% - 24px));
  width: max-content;
  z-index: 4;
}

.expand__tooltip::before {
  content: "";
  display: block;
  width: 0;
  height: 0;
  position: absolute;

  border-bottom: 8px solid transparent;
  border-top: 8px solid transparent;
  border-left: 8px solid var(--color__black);
  right: -8px;

  top: 8px;
}

.parts-list__expand-list:hover .expand__tooltip {
  display: block;
}

.parts-list__expand-icon path {
  fill: #00a3ff;
}

.parts-list__footer {
  box-shadow: 0 -3px 6px 0 rgba(0, 0, 0, 0.06);
  padding: var(--space-base);
}

#woodToprailSize {
  padding-right: 30px;
}

.sidebar-icon-button--line {
  transform: rotate(270deg);
}

.edit-popup__control--wood-size .select-box select {
  padding-right: 30px;
}

.parts-list__footer-totals {
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

.parts-list__footer-item-list-modifiers {
  align-items: center;
  display: flex;
  justify-content: flex-start;
}

.parts-list__footer-item-list-modifiers .hardware__add-item-text,
.parts-list__footer-item-list-modifiers .hardware__delete-item-text {
  display: none;
}

.parts-list__footer-item-list-modifiers:not(
    .parts-list__footer-item-list-modifiers--expanded
  )
  .hardware__add-item {
  padding: 0;
}

.parts-list__footer-item-list-modifiers:not(
    .parts-list__footer-item-list-modifiers--expanded
  )
  .hardware__add-item-icon {
  margin-right: 0;
}

.parts-list__footer-item-list-modifiers--expanded .hardware__add-item-text,
.parts-list__footer-item-list-modifiers--expanded .hardware__delete-item-text {
  display: inline-block;
}

.parts-list__button {
  background-color: var(--color__primary);
  border: 0;
  border-radius: 6px;
  box-shadow: 0 0;
  color: var(--white);
  cursor: pointer;
  display: block;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 18px;
  margin-bottom: 9px;
  padding: 12px;
  text-align: center;
  width: 100%;
}

.parts-list__button:last-child {
  margin-bottom: 0;
}

.parts-list__button.button--secondary {
  border: 1px solid #eeeeee;
  background-color: #f6f8fa;
  color: var(--color__black);
}

/* App Tooltip */
.app__tooltip {
  align-items: center;
  background-color: #ffffff;
  border: 1px solid #eeeeee;
  border-radius: 6px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.06);
  display: flex;
  justify-content: space-between;
  left: 50%;
  margin: 14px 0 0 0;
  padding: 7px 12px;
  position: absolute;
  transform: translateX(-50%);
  z-index: 1;
}

.app__tooltip-content p {
  color: rgba(49, 53, 68, 0.8);
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 18px;
  margin: 0;
}

.app__tooltip-content strong {
  color: var(--color__black);
}

.app__tooltip-close {
  align-items: center;
  background-color: #eef0f4;
  border: 0;
  border-radius: 10px;
  box-shadow: 0 0;
  cursor: pointer;
  display: flex;
  height: 20px;
  justify-content: center;
  margin-left: 9px;
  padding: 0;
  width: 20px;
}

/* .app__tooltip-close:hover,
.app__tooltip-close:focus {
  outline: 0;
} */

.app__tooltip-close-icon {
  width: 16px;
  fill: var(--color__black);
}

.app__note {
  background: var(--white);
  border: 1px solid var(--smoke);
  color: var(--color__black);
  font-size: 12px;
  letter-spacing: 0;
  line-height: 18px;
  padding: 8px;
  position: absolute;
  width: max-content;
}

.app__note-text:focus {
  outline: 0;
}

.app__note-text {
  border: 1px solid var(--smoke);
  border-radius: 4px;
}

.edit-popup__delete-button.note-save,
.edit-popup__delete-button.note-save:hover,
.edit-popup__delete-button.note-save:focus {
  background: var(--color__primary);
  border: 0;
  color: var(--white);
  margin-bottom: 4px;
}

.edit-popup {
  border: 1px solid #eeeeee;
  border-radius: 9px;
  background-color: #ffffff;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.06);
  position: absolute;
  width: max-content;
}

.edit-popup__header {
  align-items: center;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.06);
  border-bottom: 1px solid #eeeeee;
  cursor: grab;
  display: flex;
  justify-content: space-between;
  padding: 15px 18px;
}

.edit-popup__header-tabs {
  align-items: center;
  display: flex;
}

.edit-popup__heading {
  cursor: pointer;
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 18px;
  margin: 0;
  margin-right: 18px;
}

.edit-popup__content {
  padding: 15px 18px;
}

.edit-popup__label {
  font-size: 14px;
  letter-spacing: 0;
  line-height: 18px;
  margin: 0 30px 0 0;
}

.edit-popup__control {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 9px;
}

.edit-popup__control--align-top {
  align-items: flex-start;
}

.edit-popup__control:last-child {
  margin-bottom: 0;
}

.input--w-label {
  position: relative;
  margin-right: 9px;
}

.input--w-label:last-child {
  margin-right: 0;
}

.input--w-label input {
  border-radius: 6px;
  border: 0;
  background-color: #f6f8fa;
  color: var(--color__black);
  font-size: 14px;
  letter-spacing: 0;
  line-height: 18px;
  max-width: 88px;
  padding: 4px 9px;
}

.input--w-label label {
  font-size: 14px;
  letter-spacing: 0;
  line-height: 18px;
  position: absolute;
  right: 3px;
  top: 3px;
}

.labels-section input[type="range"] {
  max-width: 88px;
}

.labels-section > * {
  margin-bottom: 9px;
}

.labels-section > *:last-child {
  margin-bottom: 0;
}

.field--required {
  outline: 2px solid var(--color__red);
}

.edit-run__controls {
  max-height: 600px;
  overflow-y: scroll;
}

.edit-run__controls::-webkit-scrollbar {
  display: none;
}

.edit-popup__selector {
  padding: 2px;
  border-radius: 6px;
  background-color: #f6f8fa;
  color: var(--color__black);
}

.edit-popup__selector-button {
  border: 0;
  border-radius: 4px;
  cursor: pointer;
  display: inline-block;
  padding: 6px 30px;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 18px;
}

.edit-popup__selector-button--active {
  background-color: #00a3ff;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.06);
  color: #fff;
}

.edit-popup__section {
  padding: 18px;
  border-bottom: 1px solid #eeeeee;
}

.edit-popup__section:last-child {
  border-bottom: 0;
}

.edit-popup__position input {
  border: 0;
  color: var(--color__black);
  font-size: 14px;
  letter-spacing: 0;
  line-height: 18px;
  border-radius: 6px;
  background-color: #f6f8fa;
  padding: 4px 18px 4px 9px;
  width: 88px;
}

.edit-popup__position label {
  position: absolute;
  color: var(--color__black);
  right: 9px;
  top: 2px;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 18px;
  opacity: 0.5;
}

.edit-popup__position .labeled-input {
  position: relative;
  margin-right: 9px;
}

.edit-popup__position .labeled-input:last-child {
  margin-right: 0;
}

.edit-popup__three-buttons {
  border-radius: 6px;
  background-color: #f6f8fa;
  padding: 2px;
}

.edit-popup__three-button {
  border-radius: 4px;
  background-color: transparent;
  border: 0;
  box-shadow: 0 0;
  color: var(--color__black);
  cursor: pointer;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 18px;
  opacity: 0.8;
  padding: 2px 20px;
}

.edit-popup__three-button--active {
  background-color: #00a3ff;
  color: #ffffff;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.06);
}

.number-of-posts {
  background-color: #f6f8fa;
  border: 0;
  border-radius: 6px;
  color: var(--color__black);
  display: block;
  flex-grow: 1;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 18px;
  padding: 4px 9px;
  width: 100%;
}

.select-box {
  flex-grow: 1;
  position: relative;
}

.select-box--overriden {
  outline: 1px solid var(--color__override);
}

.run-controls .select-box {
  max-width: 208px;
}

.select-box select {
  /* for Firefox */
  -moz-appearance: none;
  /* for Chrome */
  -webkit-appearance: none;
  background-color: #f6f8fa;
  border: 0;
  border-radius: 6px;
  cursor: pointer;
  display: block;
  padding: 4px 9px;
  color: var(--color__black);
  font-size: 14px;
  letter-spacing: 0;
  line-height: 18px;
  width: 100%;
}

#project-customer::-webkit-calendar-picker-indicator {
  display: none !important;
}

#project-customer {
  /* for Firefox */
  -moz-appearance: none;
  /* for Chrome */
  -webkit-appearance: none;
  background-color: #f6f8fa;
  border: 0;
  border-radius: 6px;
  cursor: pointer;
  display: block;
  padding: 4px 9px;
  color: var(--color__black);
  font-size: 14px;
  letter-spacing: 0;
  line-height: 18px;
  padding: 9px 12px;
  width: 100%;
}

.app__processing {
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.select-box select::-ms-expand {
  display: none;
}

.select-box__icon {
  opacity: 0.6;
  fill: var(--color__black);
  position: absolute;
  pointer-events: none;
  top: 0;
  right: 0;
  width: 24px;
}

.labels__toggle-buttons {
  display: flex;
  justify-content: space-between;
  margin-bottom: 9px;
}

.labels__toggle-buttons .edit-popup__three-button {
  flex-grow: 1;
}

.cable-buttons {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  gap: 9px;
}

.cable-buttons > * {
  margin-right: 0 !important;
}

.edit-popup__control--align-top {
  align-items: flex-start;
}

.labels-section__button {
  background-color: #f6f8fa;
  border: 1px solid #eeeeee;
  border-radius: 6px;
  color: var(--color__black);
  cursor: pointer;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 18px;
  margin-right: 9px;
  opacity: 0.8;
  padding: 4.5px 22px;
  text-align: center;
}

.labels-section__button:last-child {
  margin-right: 0;
}

.edit-popup__delete-button {
  background: transparent;
  border: 1px solid #eeeeee;
  border-radius: 6px;
  box-shadow: 0 0;
  color: var(--color__black);
  cursor: pointer;
  display: block;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 18px;
  opacity: 0.8;
  padding: 6.5px;
  text-align: center;
  width: 100%;
}

.edit-popup__delete-button:hover,
.edit-popup__delete-button:active {
  background-color: #d81111;
  border-color: #d81111;
  color: #ffffff;
}

/* Project Settings */
.project-settings {
  border: 1px solid #eeeeee;
  border-radius: 9px;
  background-color: #ffffff;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.06);
  left: 70px;
  position: absolute;
  top: 10px;
  z-index: 3;
}

.project-settings__header {
  align-items: center;
  background-color: #ffffff;
  border: 1px solid #eeeeee;
  border-radius: 9px 9px 0 0;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.06);
  cursor: grab;
  display: flex;
  height: 60px;
  justify-content: space-between;
  padding: 0 18px;
  min-width: max-content;
}

.project-settings__header-tabs {
  align-items: center;
  display: flex;
  justify-content: flex-start;
}

.project-settings__tab {
  background: transparent;
  box-shadow: 0 0;
  border: 0;
  color: var(--color__black);
  cursor: pointer;
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 18px;
  opacity: 0.6;
  padding: 0 12px;
}

.project-settings__tab--active {
  opacity: 1;
}

.project-settings__content.general-details {
  padding: 42px 90px 30px;
}

.project-settings__footer {
  align-items: center;
  border-top: 1px solid #eeeeee;
  display: flex;
  justify-content: flex-end;
  padding: 30px 90px;
}

.project-settings__footer-button {
  border: 1px solid #eeeeee;
  border-radius: 6px;
  background-color: #f6f8fa;
  color: var(--color__black);
  color: rgba(49, 53, 68, 0.8);
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
  padding: 9px 30px;
  margin-right: 18px;
}

.project-settings__footer-primary-button {
  border: 0;
  border-radius: 6px;
  background-color: #00a3ff;
  color: #ffffff;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
  padding: 9px 30px;
}

.project-settings__label {
  color: var(--color__black);
  display: block;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 18px;
  margin-bottom: 6px;
  opacity: 0.8;
}

.project-title__input {
  border: 0;
  border-bottom: 1px solid #eeeeee;
  color: var(--color__black);
  font-size: 30px;
  letter-spacing: 0;
  line-height: 42px;
  margin-bottom: 6px;
  padding-bottom: 12px;
  width: 618px;
}

.project-title__input:focus {
  outline: 0;
}

.project-title {
  margin-bottom: 42px;
}

.input-subtext {
  color: var(--color__black);
  font-size: 12px;
  letter-spacing: 0;
  line-height: 15px;
  margin: 0;
  opacity: 0.6;
}

.project-people {
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
}

.project-customer,
.project-estimator {
  flex-basis: calc(50% - 9px);
  max-width: calc(50% - 9px);
  min-width: 0;
}

.project-customer--required,
.project-estimator--required {
  outline: 1px solid var(--color__red);
}

.project-customer {
  margin-right: 18px;
  position: relative;
}

.project-resellerpermit {
  align-items: center;
  display: flex;
  justify-content: flex-start;
  margin-top: 20px;
}

.reseller-permit {
  align-items: center;
  border-radius: 2px;
  border: 2px solid var(--smoke);
  cursor: pointer;
  display: flex;
  height: 15px;
  justify-content: center;
  margin: 0 9px;
  width: 15px;
}

.reseller-permit__icon {
  fill: #fff;
  height: 15px;
  width: 15px;
}

.reseller-permit__icon polygon {
  fill: #fff;
}

.reseller-permit--checked {
  background-color: #00a3ff;
  border-color: #00a3ff;
}

.people__label {
  color: var(--color__black);
  display: block;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 18px;
  opacity: 0.8;
  margin: 0 0 6px 12px;
}

.project-people .select-box select {
  padding: 9px 12px;
}

.project-people .select-box .select-box__icon {
  top: 6px;
  right: 6px;
}

.status-select-box__selected {
  text-transform: capitalize;
}

.status-select__dropdown {
  border: 1px solid #eeeeee;
  border-radius: 6px;
  background-color: #ffffff;
  box-shadow: 0 3px 6px 0 rgb(0 0 0 / 6%);
  padding: 6px;
  position: absolute;
  width: 170px;
  z-index: 1;
}

.status-select__option {
  background: transparent;
  border: 1px solid transparent;
  border-radius: 3px;
  box-shadow: 0 0;
  color: var(--color__black);
  display: block;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 18px;
  padding: 6px 9px;
  text-align: left;
  width: 100%;
}

.status-select__option:hover,
.status-select__option:focus {
  background: rgba(0, 163, 255, 0.1);
  outline: 0;
}

.status-select__option--selected {
  border: 1px solid #00a3ff;
}

.status-select-box {
  cursor: pointer;
}

.status-select-box--open .select-box__icon {
  transform: rotate(180deg);
}

.project-settings__content.general-details {
  align-items: flex-start;
  display: flex;
}

.project-settings__status {
  width: 170px;
  margin-left: 42px;
}

.project-status {
  margin-bottom: 24px;
}

.project-settings__value {
  color: var(--color__black);
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  margin: 0;
}

.project-id {
  background-color: #ffffff;
  border: 1px solid #eeeeee;
  border-radius: 6px;
  padding: 18px;
}

.project-id__item {
  margin-bottom: 18px;
}

.project-id__item:last-child {
  margin-bottom: 0;
}

.project-settings__content.railing-info {
  padding: 42px 90px 30px;
}

.railing-info__item {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}

.tools-info__item {
  margin-right: 30px;
}

.tracking-item {
  width: 100%;
}

.tools-info__item:last-child {
  margin-right: 0;
}

.tools-info__item-label {
  margin-bottom: 9px;
}

.railing-info__item:last-child {
  margin-bottom: 0;
}

.railing-info__label {
  width: 344px;
}

.railing-info__options {
  align-items: center;
  display: flex;
  justify-content: flex-start;
  width: 700px;
}

.settings-select {
  background: var(--grey-1);
  border: 0;
  border-radius: 6px;
  color: var(--color__black);
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 24px;
  padding: 6px 36px 6px 12px;
}

.railing-info__option-label {
  color: var(--color__black);
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  margin: 0;
  margin-bottom: 6px;
}

.railing-info__option-label:last-child {
  margin-bottom: 0;
}

.railing-info__subtext {
  color: var(--color__black);
  font-size: 14px;
  letter-spacing: 0;
  line-height: 18px;
  margin: 0;
  opacity: 0.8;
}

.railing-info__option {
  border: 1px solid #eeeeee;
  border-radius: 6px;
  background-color: #ffffff;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.06);
  color: rgba(49, 53, 68, 0.8);
  cursor: pointer;
  flex-basis: 152px;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 24px;
  margin-right: 18px;
  max-width: 152px;
  min-width: 0;
  padding: 6px;
  text-align: center;
}

.railing-info__option--text {
  color: rgba(49, 53, 68, 0.8);
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
}

.railing-info__option--active {
  border-color: #00a3ff;
  color: rgb(49, 53, 68);
}

.railing-info__option:last-child {
  margin-right: 0;
}

.railing-info__subtext {
  opacity: 0.8;
  color: var(--color__black);
  font-size: 14px;
  letter-spacing: 0;
  line-height: 18px;
}

.railing-info__image-option {
  border: 1px solid #eeeeee;
  border-radius: 6px;
  background-color: #ffffff;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.06);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 24px;
  padding: 0;
  text-align: center;
  width: 100%;
}

.option__image-container {
  max-width: 152px;
  min-width: 0;
  margin-right: 18px;
  flex-basis: 152px;
}

.option__image-container:last-child {
  margin-right: 0;
}

.option__image-label {
  color: var(--color__black);
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 24px;
  margin: 0;
  text-align: center;
}

.option__image-label--anodized {
  font-size: 12px;
}

.railing-info__image-option--active {
  border-color: #00a3ff;
}

.post-styles {
  padding: 42px 90px 30px;
}

.post-material__item .railing-info__option {
  height: 62px;
}

.color-item {
  display: block;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: #524651;
  margin: 16px;
}

.color-item.black {
  background-color: #2c2330;
}

.color-item.white {
  border: 1px solid #eeeeee;
  background-color: #f3f3f3;
}

.color-item.clay {
  background-color: #dec1bd;
}

.color-item.natural {
  background-color: #cbc1cf;
}

.important {
  color: #d81111;
  opacity: 1;
}

/* Parts List */
.parts-list-component {
  height: 100%;
  max-height: 665px;
  overflow-y: scroll;
}

.parts-list-component::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.parts-list-component {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.parts-list--empty {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.empty-parts-list {
  border: 1px dashed #dddddd;
  border-radius: 6px;
  color: var(--color__black);
  font-size: 18px;
  letter-spacing: -0.2px;
  line-height: 24px;
  margin: 18px;
  opacity: 0.8;
  padding: 54px 40px;
  text-align: center;
}

.item-list {
  border-collapse: collapse;
  margin: 15px 6px;
}

.item-list__container {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  width: 100%;
}

.item-list thead {
  background: #fff;
  position: sticky;
  top: 0;
  z-index: 1;
}

.item-list thead th {
  color: var(--color__black);
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 15px;
  padding: 6px;
  text-align: left;
}

.table-item {
  color: var(--color__black);
  font-size: 12px;
  letter-spacing: 0;
  line-height: 15px;
  padding: 6px;
  position: relative;
}

.edit-qb-value__button {
  background: transparent;
  border: 0;
  display: block;
  margin: 0;
  margin-left: 2px;
  padding: 0;
  position: relative;
}

.edit-qb-item__window {
  background: #fff;
  border: 1px solid #d7d7d7;
  border-radius: 4px;
  padding: 6px;
  position: absolute;
  top: 200px;
  left: 20px;
  width: 400px;
  z-index: 2;
}

.edit-qb-item__description {
  border: 1px solid #d7d7d7;
  border-radius: 4px;
  display: block;
  margin-bottom: 12px;
  width: 100%;
  white-space: pre-wrap;
}

.edit-qb-item__price {
  border: 1px solid #d7d7d7;
  border-radius: 4px;
  margin-bottom: 12px;
  padding: 4px;
}

.parts-list--expanded .table-item--desc {
  white-space: pre-wrap;
}

.edit-qb-item__window .edit-qb-item__submit-button {
  display: block;
  padding: 8px;
  margin-bottom: 8px;
  width: 100%;
}

.edit-qb-item__window .edit-qb-item__submit-button:last-child {
  margin-bottom: 0;
}

.edit-qb-item__icon {
  align-items: center;
  display: flex;
  height: 16px;
  justify-content: center;
  width: 16px;
}

.item-list tbody tr:nth-child(2n) {
  border-radius: 3px;
  background-color: #f6f8fa;
}

.unit-measure {
  opacity: 0.4;
  margin: 0 4px;
}

.table-item--desc {
  white-space: pre-wrap;
}

.parts-list__total {
  align-items: center;
  display: flex;
  justify-content: flex-end;
}

.parts-list__total-label {
  opacity: 0.8;
  color: var(--color__black);
  font-size: 15px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 18px;
}

.parts-list__total-area {
  align-items: center;
  display: flex;
  justify-content: flex-end;
}

.parts-list__tax-area {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.tax__button {
  align-items: center;
  background: #fff;
  border: 1px solid #dddddd;
  border-radius: 3px;
  display: flex;
  height: 28px;
  justify-content: center;
  margin-left: 6px;
  padding: 0;
  position: relative;
  width: 28px;
}

.tax__button-icon {
  fill: var(--color__black);
  height: 12px;
  width: 12px;
}

.tax__button-icon-delete {
  height: 20px;
  width: 20px;
}

.tax__button--delete:hover,
.tax__button--delete:focus {
  background: #d81111;
  border-color: #d81111;
}

.tax__button--delete:hover .tax__button-icon-delete path,
.tax__button--delete:focus .tax__button-icon-delete path {
  fill: #fff;
}

.parts-list__tax-showing .parts-list__total-value {
  min-width: 70px;
  text-align: right;
}

.parts-list__tax-showing {
  margin-bottom: 15px;
}

.parts-list__grandtotal,
.parts-list__subtotal {
  margin-right: calc(28px + 6px);
}

.tax__button:hover .tax__button-icon,
.tax__button:focus .tax__button-icon {
  fill: #00a3ff;
}

/* Projects Dashboard */
.projects__header {
  background: #fafcfe;
  padding: 30px 0px;
  position: sticky;
  top: 0;
  z-index: 1;
}

.projects__header-container {
  justify-content: space-between;
  display: flex;
  align-items: center;
  margin: 0 auto;
  max-width: 1440px;
  padding: 0 90px;
}

.projects__search-icon {
  height: 32px;
  position: absolute;
  top: 12px;
  left: 16px;
  width: 32px;
  opacity: 0.8;
}

.projects__search-icon path {
  fill: var(--color__black);
}

.add-project__icon {
  width: 24px;
  height: 24px;
}

.projects__scr-logo {
  width: 150px;
}

.projects__search-input {
  border: 1px solid #eeeeee;
  border-radius: 9px;
  background-color: #ffffff;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.06);
  padding: 12px 110px 12px 54px;
  color: var(--color__black);
  font-size: 18px;
  letter-spacing: 0;
  line-height: 30px;
  width: 640px;
}

.projects__search-input::placeholder {
  opacity: 0.6;
}

.projects__search-container {
  position: relative;
}

.projects__add-project {
  align-items: center;
  background-color: #00a3ff;
  border: 0;
  border-radius: 6px;
  box-shadow: 0 0;
  color: #ffffff;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  font-weight: 600;
  justify-content: space-between;
  letter-spacing: 0;
  line-height: 24px;
  padding: 9px 24px 9px 18px;
}

.add-project__text {
  margin-top: 1px;
  margin-left: 10px;
}

.add-project__icon polygon {
  fill: #ffffff;
}

.projects__dashboard-table-header {
  background-color: #ffffff;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.06);
  height: 54px;
  position: sticky;
  top: 116px;
}

.dashboard-header__container {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: flex-start;
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 90px 0 30px;
}

.dashboard__header-item-icon {
  width: 24px;
  height: 24px;
}

.projects__dashboard-delete {
  align-items: center;
  background-color: #d81111;
  border: 0;
  border-radius: 6px;
  box-shadow: 0 0;
  display: flex;
  height: 30px;
  justify-content: center;
  padding: 0;
  width: 30px;
}

.dashboard__header-item.delete-project {
  width: 60px;
}

.dashboard__delete-icon {
  width: 24px;
  height: 24px;
}

.dashboard__delete-icon path {
  fill: #ffffff;
}

.dashboard__header-item {
  align-items: center;
  color: var(--color__black);
  cursor: pointer;
  display: flex;
  font-size: 16px;
  font-weight: 600;
  justify-content: flex-start;
  letter-spacing: 0;
  line-height: 24px;
}

.dashboard__header-item-icon {
  opacity: 0.3;
}

.dashboard__header-item-icon path {
  fill: var(--color__black);
}

.dashboard__header-item-select {
  background-color: #f6f8fa;
  border: 0;
  border-radius: 6px;
  color: rgba(49, 53, 68, 0.8);
  font-size: 12px;
  letter-spacing: 0;
  line-height: 18px;
  margin-left: 12px;
  padding: 4px;
}

.dashboard__table-item-data {
  cursor: pointer;
  display: flex;
}

.dashboard__header-item.project-name,
.dashboard__table-item.project-name {
  width: 250px;
}

.dashboard__header-item.date-created,
.dashboard__table-item.date-created {
  width: 173px;
}

.dashboard__header-item.estimate-number,
.dashboard__table-item.estimate-number {
  width: 130px;
}

.dashboard__header-item.estimator,
.dashboard__table-item.estimator {
  width: 260px;
}

.dashboard__header-item.customer,
.dashboard__table-item.customer {
  width: 240px;
}

.dashboard__header-item.status,
.dashboard__table-item.status {
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 207px;
}

.dashboard__table-item.delete-project {
  width: 55px;
}

.project__dashboard-table-item {
  align-items: center;
  display: flex;
  justify-content: flex-start;
}

.project__dashboard-table-item-container {
  margin: 0 auto;
  max-width: 1440px;
  padding: 0 90px 0 35px;
}

.project__dashboard-table-item {
  border-bottom: 1px solid #eeeeee;
  padding: 22px 0;
}

/* Hardware Tab */
.hardware__footer {
  align-items: center;
  background: #ffffff;
  display: flex;
  justify-content: flex-start;
  padding: 15px 17px;
}

.hardware-table__header-item {
  color: var(--color__black);
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 15px;
  padding: 6px;
  text-align: left;
}

.hardware-table__item {
  color: var(--color__black);
  font-size: 12px;
  letter-spacing: 0;
  line-height: 15px;
  padding: 6px;
}

.hardware-table__row--overriden {
  outline: 1px solid var(--color__override);
}

.table-item__row--overriden {
  outline: 1px solid var(--color__override);
}

.hardware-table__row:nth-child(2n) {
  background-color: #f6f8fa;
  border-radius: 3px;
}

.hardware__table {
  border-collapse: collapse;
  margin: 6px;
  min-width: 400px;
  padding-right: 6px;
}

.hardware__table-container {
  max-height: 500px;
  overflow-y: scroll;
}

.hardware__table-container::-webkit-scrollbar {
  display: none;
}

.hardware-table__item.description {
  white-space: pre-wrap;
}

.hardware-table__quantity-input {
  border: 1px solid var(--smoke);
  border-radius: 3px;
  max-width: 60px;
}

.hardware-table__quantity-input:focus {
  outline: 0;
  border-color: var(--color__primary);
}

.hardware__add-item {
  align-items: center;
  background: #ffffff;
  border: 1px dashed #dddddd;
  border-radius: 3px;
  color: var(--color__black);
  cursor: pointer;
  display: flex;
  font-size: 12px;
  justify-content: space-between;
  letter-spacing: 0;
  line-height: 15px;
  padding: 0;
  padding: 0 12px 0 6px;
}

.hardware__add-item-icon {
  height: 24px;
  margin-right: 9px;
  width: 24px;
}

.hardware__add-item-icon path {
  fill: var(--color__black);
}

.hardware__delete-item {
  align-items: center;
  background: var(--color__red);
  border: 0;
  border-radius: 3px;
  color: var(--white);
  cursor: pointer;
  display: flex;
  margin-right: 9px;
  padding-right: 12px;
}

.project-dashboard__selected {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
}

.project-dashboard__selected__button {
  align-items: center;
  background: var(--color__red);
  border: 0;
  border-radius: 3px;
  color: var(--white);
  cursor: pointer;
  display: flex;
  font-size: 18px;
  margin-right: 9px;
  padding-right: 12px;
  padding-top: 6px;
  padding-bottom: 6px;
  margin-bottom: 12px;
  width: max-content;
}

.project-dashboard__delete-item-icon {
  width: 32px;
  height: 32px;
}

.project-dashboard__delete-item-icon path {
  fill: #fff !important;
}

.project-dashboard__clear-selected__button {
  background: var(--color__primary);
}

.hardware__delete-item--remove-overrides {
  background: var(--color__override);
  border-color: var(--color__override);
}

.hardware__add-item--add-overages {
  background: var(--color__green);
  border-color: var(--color__green);
  color: #fff;
}

.hardware__add-item:last-child {
  margin-left: 9px;
}

.hw--overrages .hardware__add-item-icon polygon,
.hw--overrages .hardware__add-item-icon path {
  fill: #fff;
}

.hardware__delete-item-icon {
  height: 24px;
  width: 24px;
}

.hw--overrages {
  color: #fff;
  font-size: 13.3333px;
  line-height: 17px;
}

.hardware__add-item {
  position: relative;
}

.hardware__add-item:hover .expand__tooltip {
  left: -8px;
  height: 35px;
  top: 13px;
  right: auto;
  transform: translate3d(-100%, -50%, 0);
  display: block;
}

.hardware__delete-item-icon path {
  fill: var(--white);
}

/* Add Hardware Menu */
.add-hardware {
  background: var(--white);
  border: 1px solid #eeeeee;
  border-radius: 9px;
  box-shadow: 0 3px 6px 0 rgb(0 0 0 / 6%);
  left: 200px;
  min-width: 600px;
  position: absolute;
  top: 200px;
}

.add-hardware__header {
  align-items: center;
  box-shadow: 0 3px 6px 0 rgb(0 0 0 / 6%);
  border-bottom: 1px solid #eeeeee;
  cursor: grab;
  display: flex;
  justify-content: space-between;
  padding: 15px 18px;
}

.add-hardware__container {
  position: relative;
}

.add-hardware__list {
  background: var(--white);
  border: 1px solid #eeeeee;
  border-radius: 6px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.06);
  max-height: 500px;
  overflow-y: auto;
  padding: 15px 18px;
  position: absolute;
  top: 60px;
  left: 18px;
  width: 700px;
}

.add-hardware__list::-webkit-scrollbar {
  display: none;
}

.add-hardware__item {
  align-items: flex-start;
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  padding: 6px;
}

.add-hardware__item:nth-child(2n) {
  background: var(--grey-1);
}

.add-hardware__line-drawing {
  border: 1px solid #eeeeee;
  flex-shrink: 0;
  height: 60px;
  margin-right: 12px;
  width: 60px;
}

.add-hardware__item-name {
  opacity: 0.8;
  color: var(--color__black);
  font-size: 12px;
  letter-spacing: 0;
  line-height: 14px;
  margin: 0 0 6px;
}

.add-hardware__item-description {
  color: var(--color__black);
  font-size: 14px;
  letter-spacing: 0;
  line-height: 18px;
  margin: 0;
  white-space: pre-wrap;
}

.add-hardware__search-container {
  position: relative;
}

.add-hardware__search-icon {
  fill: var(--color__black);
  height: 32px;
  left: 12px;
  opacity: 0.6;
  position: absolute;
  top: 20px;
  width: 32px;
}

.add-hardware__search {
  border: 0;
  color: var(--color__black);
  font-size: 18px;
  letter-spacing: 0;
  line-height: 30px;
  padding: 21px 18px 21px 51px;
  width: 100%;
}

.add-hardware__product-selection {
  align-items: center;
  display: flex;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 30px;
  padding: 21px 18px 21px 18px;
  position: relative;
  width: 100%;
}

.add-hardware__clear-product-selection {
  background: var(--white);
  border: 0;
  box-shadow: 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
  padding: 0;
}

.add-hardware__clear-product-icon {
  width: 30px;
  height: 30px;
}

.add-hardware__search:focus {
  outline: 0;
}

.add-hardware__search::placeholder {
  color: rgba(49, 53, 68, 0.6);
}

.add-hardware__label {
  color: var(--color__black);
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 18px;
}

.add-hardware__footer {
  border-top: 1px solid #eee;
  padding: 30px;
}

.add-hardware__button {
  background-color: #00a3ff;
  border: 0;
  box-shadow: 0 0;
  border-radius: 6px;
  color: #ffffff;
  cursor: pointer;
  display: block;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 24px;
  padding: 9px 18px;
  width: 100%;
  text-align: center;
}

.add-hardware__quantity {
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 0 0 18px;
}

.add-hardware__quantity-label {
  color: var(--color__black);
  font-size: 14px;
  letter-spacing: 0;
  line-height: 18px;
  margin: 0 18px 0 0;
}

.add-hardware__quantity-input {
  background-color: #f6f8fa;
  border: 0;
  border-radius: 6px;
  color: var(--color__black);
  font-size: 14px;
  letter-spacing: 0;
  line-height: 18px;
  padding: 4px 9px;
  width: 90px;
}

/* App Options Menu */
.app__options {
  background: #fff;
  border: 1px solid #eee;
  border-radius: 6px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.06);
  left: 70px;
  position: absolute;
  top: 10px;
  width: 240px;
}

.options__link {
  background: transparent;
  border: 0;
  box-shadow: 0 0;
  color: var(--color__black);
  cursor: pointer;
  display: block;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 18px;
  padding: 6px 9px;
}

.options__middle {
  border-bottom: 1px solid #eee;
  border-top: 1px solid #eee;
  padding: 6px 9px;
}

.options__top-link,
.options__bottom-link {
  padding: 12px 18px;
}

/* Revisions Description */
.revisions-description__container {
  border: 1px solid #eeeeee;
  border-radius: 6px;
  background-color: #ffffff;
  box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.06);
  position: absolute;
  top: 50%;
  left: 50vw;
  transform: translate3d(-50%, -50%, 0);
  width: 420px;
}

.revisions-description__content {
  padding: 30px 30px 18px;
}

.revisions-description__message {
  margin: 0 0 18px;
  text-align: center;
  color: rgba(49, 53, 68, 0.8);
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
}

.revisions-description__project-name {
  color: rgba(49, 53, 68, 1);
}

.revisisons-description__textarea {
  border: 1px solid #eee;
  border-radius: 6px;
  background-color: #f6f8fa;
  color: var(--color__black);
  font-family: var(--font-main);
  font-size: 14px;
  letter-spacing: 0;
  line-height: 18px;
  height: 95px;
  padding: 6px 12px;
  width: 100%;
}

.revisisons-description__textarea:focus {
  border-color: #00a3ff;
  outline: 0;
}

.revisions-description__footer {
  align-items: center;
  border-top: 1px solid #eee;
  display: flex;
  justify-content: space-between;
  padding: 18px 40px;
}

.button {
  cursor: pointer;
  box-shadow: 0 0;
}

.button--secondary {
  background-color: #f6f8fa;
  border: 1px solid #eeeeee;
  border-radius: 6px;
  color: rgba(49, 53, 68, 0.8);
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 18px;
  padding: 12.5px 26px;
  text-align: center;
}

.button--primary {
  border: 0;
  border-radius: 6px;
  background-color: #00a3ff;
  color: #ffffff;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 18px;
  padding: 12.5px 30px;
  text-align: center;
}

.no-thanks {
  margin-right: 18px;
}

/* Revisions Menu */
.revisions-menu {
  background: #fff;
  border: 1px solid #eeeeee;
  border-radius: 9px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.06);
  left: 70px;
  position: absolute;
  top: 10px;
  width: max-content;
  max-width: 340px;
}

.revisions-menu__header {
  align-items: center;
  background-color: #ffffff;
  border-bottom: 1px solid #eeeeee;
  border-radius: 9px 9px 0 0;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.06);
  display: flex;
  justify-content: space-between;
  padding: 15px 18px;
  position: relative;
  z-index: 4;
}

.revisions-menu__item {
  align-items: flex-start;
  display: flex;
  padding: 12px 18px;
  position: relative;
}

.revisions-menu__item:hover {
  background: #f6f8fa;
}

.revision-item__button {
  background-color: #ffffff;
  border: 2px solid #eeeeee;
  border-radius: 50%;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.06);
  cursor: pointer;
  display: flex;
  height: 18px;
  margin-right: 12px;
  position: relative;
  width: 18px;
  z-index: 3;
}

.revision-item__content {
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
}

.revision-item__content .hardware__delete-item {
  margin-left: 9px;
}

.revisions-menu__item::after {
  content: "";
  display: block;
  width: 2px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 26px;
  background: #eee;
  z-index: 1;
}
.revisions-menu__item::before {
  content: "";
  display: block;
  width: 2px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 26px;
  background: #eee;
  z-index: 1;
}

.revisions-menu__item:first-child::before {
  z-index: 2;
  background: #fff;
  height: 12px;
}

.revisions-menu__item:last-child::after {
  z-index: 2;
  background: #fff;
  bottom: 0;
  top: auto;
  height: calc(100% - 18px);
}

.revisions-menu__item:hover:first-child::before,
.revisions-menu__item:hover:last-child::after {
  background: #f6f8fa;
}

.revision-item__button--active {
  border-color: #00a3ff;
}

.revision-item__datetime {
  color: var(--color__black);
  font-size: 14px;
  letter-spacing: 0;
  line-height: 18px;
}

.revision-item__description {
  opacity: 0.8;
  color: var(--color__black);
  font-size: 12px;
  letter-spacing: 0;
  line-height: 18px;
  margin-top: 3px;
}

.revisions-menu__items::-webkit-scrollbar {
  display: none;
}

.revisions-menu__items {
  padding-bottom: 15px;
  max-height: calc(100vh - 72px);
  overflow-y: scroll;
}

.app__canvas-manager {
  align-items: center;
  background: var(--white);
  bottom: 0;
  display: flex;
  height: 36px;
  justify-content: space-between;
  left: 60px;
  padding: 6px 10px;
  position: absolute;
  width: calc(100vw - 60px);
  z-index: 2;
}

.app__canvases {
  align-items: center;
  background: var(--white);
  bottom: 0;
  display: flex;
  height: 36px;
  justify-content: flex-start;
}

.canvas-button {
  align-items: center;
  border-radius: 6px;
  color: var(--color__black);
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  margin-right: 6px;
  padding: 3px 6px;
  position: relative;
}

.canvas-button:hover {
  background-color: #eef0f4;
}

.canvas-button--active,
.canvas-button--active:hover {
  background-color: #00a3ff;
  color: var(--white);
}

.canvas-button__text {
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 18px;
}

.canvas-button__menu-toggle {
  align-items: center;
  background: var(--white);
  border: 0;
  border-radius: 50%;
  display: flex;
  height: 14px;
  justify-content: center;
  margin-left: 3px;
  padding: 0;
  width: 14px;
}

.canvas-button__menu-toggle--open {
  transform: rotate(180deg);
}

.canvas-button:hover .canvas-button__menu-toggle {
  background-color: #eef0f4;
}

.canvas-button--active .canvas-button__menu-toggle,
.canvas-button--active:hover .canvas-button__menu-toggle {
  background: transparent;
}

.canvas-button__menu-toggle:hover {
  background-color: #eef0f4;
}

.canvas-button__icon {
  height: 16px;
  width: 16px;
}

.canvas-button--active
  .canvas-button__menu-toggle
  .canvas-button__icon
  polygon {
  fill: var(--white);
}

.canvas-manager__add-button {
  align-items: center;
  background: var(--white);
  border: 0;
  display: flex;
  justify-content: center;
}

.canvas-manager__add-button:hover .canvas-manager__add-icon,
.canvas-manager__add-button:focus .canvas-manager__add-icon {
  opacity: 1;
}

.canvas-manager__add-button:hover .canvas-manager__add-icon polygon,
.canvas-manager__add-button:focus .canvas-manager__add-icon polygon {
  fill: #00a3ff;
}

.canvas-manager__add-icon {
  cursor: pointer;
  fill: var(--color__black);
  height: 24px;
  opacity: 0.8;
  width: 24px;
}

.canvas-menu {
  background-color: #ffffff;
  border: 1px solid #eeeeee;
  border-radius: 6px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.06);
  bottom: calc(36px + 3px);
  color: #333;
  left: 0;
  position: absolute;
  width: 150px;
  z-index: 1;
}

.canvas-menu__top {
  padding: 6px;
}

.canvas-menu__bottom {
  border-top: 1px solid var(--smoke);
  padding: 6px;
}

.canvas-menu__button {
  border: 0;
  border-radius: 3px;
  background: transparent;
  color: var(--color__black);
  cursor: pointer;
  display: block;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 18px;
  padding: 6px 9px;
  text-align: left;
  width: 100%;
}

.canvas-menu__button:hover,
.canvas-menu__button:focus {
  background: #00a3ff1a;
}

.rename-canvas-menu {
}

.rename-canvas-menu__input {
  border: 1px solid var(--smoke);
  border-radius: 3px;
  display: block;
  width: 100%;
  padding: 6px 9px;
}

.rename-canvas-menu__input:focus {
  border-color: #00a3ff;
  outline: 0;
}

/* Canvas Settings */
.canvas-settings {
  border: 1px solid #eeeeee;
  border-radius: 9px;
  background-color: #ffffff;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.06);
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: max-content;
  z-index: 10;
}

.canvas-settings__header {
  align-items: center;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.06);
  border-bottom: 1px solid #eeeeee;
  display: flex;
  justify-content: space-between;
  padding: 15px 18px;
}

.canvas-settings__header-tabs {
  align-items: center;
  display: flex;
}

.canvas-settings__heading {
  cursor: pointer;
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 18px;
  margin: 0;
  margin-right: 18px;
}

.canvas-settings__content {
  padding: 15px 18px;
}

/* Login */
.login {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
}

.login-form {
  width: 100%;
}

.login__form {
  margin: 0 auto;
  max-width: 300px;
  width: 100%;
}

.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login__label {
  display: block;
  margin-bottom: 6px;
}

.login__input {
  border-radius: 3px;
  display: block;
  padding: 0.5rem 1rem;
  border: 1px solid var(--smoke);
  width: 100%;
}

.error {
  color: red;
  padding: 6px;
}

.login__button {
  display: block !important;
  margin-top: 20px;
  width: 100%;
}

/* Window Manager */
.app__window-manager {
  height: 100%;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 100%;
}

/* Estimator Select */
.estimators__box {
  position: relative;
}

.estimators__select {
  -webkit-appearance: none;
  background-color: #f6f8fa;
  border: 0;
  border-radius: 6px;
  color: var(--color__black);
  cursor: pointer;
  display: flex;
  flex-wrap: wrap;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 18px;
  padding: 9px 12px;
  width: 100%;
}

.estimators__select--items {
  padding: 0 0 6px;
}

.estimator__selected-item {
  align-items: center;
  background-color: #00a3ff;
  border-radius: 4px;
  color: #ffffff;
  display: flex;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: -0.2px;
  line-height: 24px;
  margin-left: 6px;
  margin-top: 6px;
  padding: 3px 9px;
}

.estimator__selected-item-remove {
  background: transparent;
  border: 0;
  box-shadow: 0 0;
  height: 20px;
  margin: 0;
  padding: 0;
}

.estimator__selected-item-icon {
  height: 20px;
  width: 20px;
}

.estimator__selected-item-icon polygon {
  fill: #fff;
}

.estimator__selected-item-remove {
  margin-left: 6px;
}

.edit-customer-shipping-address {
  border: 1px solid #eeeeee;
  border-radius: 6px;
  background-color: #ffffff;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.06);
  position: absolute;
  width: 100%;
  z-index: 2;
}

.estimators__dropdown {
  border: 1px solid #eeeeee;
  border-radius: 6px;
  background-color: #ffffff;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.06);
  position: absolute;
  width: 100%;
  z-index: 2;
}

.estimators__search {
  align-items: center;
  border-bottom: 1px solid #eeeeee;
  display: flex;
  justify-content: flex-start;
  padding: 15px 18px;
}

.estimators__search-icon {
  fill: var(--color__black);
  margin-right: 9px;
  opacity: 0.8;
  width: 20px;
}

.estimators__search-input {
  border: 0;
  color: var(--color__black);
  font-family: var(--font-main);
  font-size: 14px;
  letter-spacing: 0;
  line-height: 18px;
}

.estimators__search-input:focus {
  outline: 0;
}

.estimators__new {
  padding: 6px;
}

.estimators__add-new {
  align-items: center;
  background: #fff;
  border: 1px dashed #dddddd;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  padding: 9px;
  width: 100%;
}

.estimators__add-text {
  color: var(--color__black);
  font-family: var(--font-main);
  font-size: 12px;
  letter-spacing: 0;
  line-height: 15px;
}

.estimators__add-icon {
  fill: var(--color__black);
  margin-right: 9px;
  opacity: 0.8;
  width: 20px;
}

.add-alternate__contact {
  border-top: 1px solid #eee;
}

.estimators__list {
  list-style: none;
  margin: 0;
  max-height: 280px;
  overflow-y: scroll;
  padding: 0 6px 6px;
}

.estimators__list::-webkit-scrollbar {
  display: none;
}

.esimators__list--popup-open {
  overflow-y: visible;
}

.estimator__select {
  align-items: center;
  display: flex;
  flex-grow: 1;
  justify-content: flex-start;
}

.estimator__checkbox {
  background-color: #ffffff;
  border: 1px solid #dddddd;
  border-radius: 2px;
  height: 16px;
  margin: 0 9px 0 0;
  visibility: hidden;
  width: 16px;
}

.estimator__checkbox::before {
  align-items: center;
  background: #fff;
  content: "";
  cursor: pointer;
  border: 1px solid #dddddd;
  border-radius: 2px;
  display: flex;
  height: 16px;
  justify-content: center;
  margin: 0 9px 0 0;
  visibility: visible;
  width: 16px;
}

.estimator__checkbox:checked::before {
  background: rgb(0, 163, 255);
  border-color: rgb(0, 163, 255);
  color: #fff;
  content: "✔";
}

.estimators__estimator {
  border: 1px solid transparent;
  border-radius: 3px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: 6px 9px;
  position: relative;
}

.estimators__estimator.estimators__estimator--checked {
  border: 1px solid #00a3ff;
  background: #fff;
}

.estimator__select {
  cursor: pointer;
}

.estimators__estimator:hover {
  background-color: rgba(0, 163, 255, 0.1);
}

.estimators__estimator:hover .estimator__checkbox::before {
  border-color: #00a3ff;
}

.estimator__name {
  cursor: pointer;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 18px;
}

.estimators__estimator--archived .estimator__name {
  opacity: 0.6;
}

.estimator__more {
  align-items: center;
  background: transparent;
  border: 0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  padding: 0;
  width: 20px;
}

.estimator__more-icon {
  fill: var(--color__black);
  opacity: 0.3;
  transition: opacity 300ms ease-in-out;
}

.estimator__more:hover .estimator__more-icon,
.estimator__more:focus .estimator__more-icon {
  opacity: 1;
}

.estimator__more:hover .estimator__more-icon path,
.estimator__more:focus .estimator__more-icon path {
  fill: #00a3ff;
}

.estimators__add-estimator {
  background-color: #fff;
  border: 1px solid #eeeeee;
  border-radius: 9px;
  box-shadow: 0 3px 6px 0 rgb(0 0 0 / 6%);
  position: absolute;
  width: 100%;
  z-index: 2;
}

.add-estimator__fields {
  padding: 15px 18px;
}

.add-customer__addresses {
  border-top: 1px solid #eee;
}

.add-estimator__field select {
  width: 174px;
}

.address__add-new {
  align-items: center;
  background-color: #f6f8fa;
  border: 1px solid #eeeeee;
  border-radius: 3px;
  display: flex;
  height: 34px;
  justify-content: center;
  margin-bottom: 8px;
  padding: 9.5px;
  width: 100%;
}

.add-customer__ship-to-bill-to-same label {
  display: flex;
  align-items: center;
}

.add-customer__ship-to-bill-to-same span {
  font-size: 12px;
}

.add-customer__ship-to-bill-to-same + .address__add-new {
  margin-top: 8px;
}

.address-input {
  background-color: #f6f8fa;
  border: 1px solid #eeeeee;
  color: var(--color__black);
  display: block;
  font-family: "Source Sans Pro";
  font-size: 14px;
  height: 26px;
  letter-spacing: 0;
  line-height: 18px;
  margin-bottom: 9px;
  width: 100% !important;
}

.input--error {
  border: 1px solid var(--color__red) !important;
}

.add-address__label {
  min-width: 100px;
}

.add-address__field.add-estimator__field {
  align-items: flex-start;
}

.add-address__state-zip {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.add-address__state-zip input:first-child,
.add-address__state-zip select:first-child {
  margin-right: 9px;
}

.customer-details {
  max-height: 400px;
  overflow-y: auto;
}

.customer-details::-webkit-scrollbar {
  display: none;
}

.address__remove {
  align-items: center;
  background-color: #fff;
  border: 1px dashed #dddddd;
  border-radius: 3px;
  display: flex;
  height: 34px;
  justify-content: center;
  padding: 9.5px;
  width: 100%;
}

.address__add-new:last-child {
  margin-bottom: 0;
}

.add-estimator__field {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 9px;
}

.add-estimator__field:last-child {
  margin-bottom: 0;
}

.add-estimator__field-label {
  color: var(--color__black);
  font-size: 14px;
  letter-spacing: 0;
  line-height: 18px;
}

.add-estimator__field-input {
  background-color: #f6f8fa;
  border: 0;
  border-radius: 6px;
  color: var(--color__black);
  font-size: 14px;
  letter-spacing: 0;
  line-height: 18px;
  padding: 4px 9px;
}

.add-estimator__field-input:focus {
  outline: 0;
}

.add-estimator__footer {
  align-items: center;
  border-top: 1px solid #eee;
  display: flex;
  justify-content: center;
  padding: 18px;
}

.add-estimator__footer-button {
  background-color: #00a3ff;
  border-radius: 6px;
  color: #ffffff;
  display: block;
  font-family: var(--font-main);
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 18px;
  text-align: center;
  padding: 6px;
  width: 100%;
}

.estimator__popup-window {
  background-color: #ffffff;
  border: 1px solid #eeeeee;
  border-radius: 6px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.06);
  padding: 6px;
  position: absolute;
  right: 0;
  top: 26px;
  z-index: 1;
}

.estimator__popup-window-action {
  background: transparent;
  border: 0;
  border-radius: 3px;
  box-shadow: 0 0;
  color: var(--color__black);
  display: block;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 18px;
  padding: 6px 9px;
  text-align: left;
  width: 100%;
}

.estimator__popup-window-action:hover {
  background-color: rgba(0, 163, 255, 0.1);
}

.error-message {
  background-color: #ffffff;
  border: 1px solid #eeeeee;
  border-radius: 6px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.06);
  max-width: 400px;
  min-width: 300px;
  padding: var(--space-base);
  position: absolute;
  left: 400px;
  text-align: center;
  top: 400px;
  width: max-content;
}

.qb-export-dialog {
  max-width: max-content;
  text-align: left;
}

.error-message a {
  text-decoration: none;
}

.error-message__button {
  display: block;
  margin-top: 16px;
  width: 100%;
}

.error-message.edit-run-length {
  min-width: 224px;
}

.angle-section {
  align-items: center;
  display: flex;
}

/* Project Summary */
.project-summary {
  padding: 0 18px 18px;
}

.parts-list--expanded .project-summary {
  display: flex;
  flex-wrap: wrap;
}

.project-summary__item {
  align-items: baseline;
  display: flex;
  margin-bottom: 9px;
}

.project-summary__item:last-child {
  margin-bottom: 0;
}

.parts-list--expanded .project-summary__item {
  margin-right: 24px;
}

.project-summary__item-label {
  color: var(--color__black);
  font-size: 12px;
  letter-spacing: 0;
  line-height: 18px;
  opacity: 0.6;
  width: 85px;
}

.parts-list--expanded .project-summary__item-label {
  margin-right: 9px;
  width: max-content;
}

.project-summary__item-value {
  color: var(--color__black);
  font-size: 14px;
  letter-spacing: 0;
  line-height: 18px;
}

.project-summary--open {
  transform: rotate(180deg);
}

.customer-edit {
  margin-top: 9px;
}

.customer-edit__button {
  background-color: #f6f8fa;
  border: 1px solid #eeeeee;
  border-radius: 6px;
  color: var(--color__black);
  cursor: pointer;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 18px;
  margin-right: 9px;
  opacity: 0.8;
  padding: 4.5px 22px;
  text-align: center;
}

.qb-export {
  margin-top: 9px;
}

.qb-export__button {
  /* background-color: #f6f8fa;


  opacity: 0.8;
  padding: 4.5px 22px;
  text-align: center; */

  border: 0;
  border-radius: 6px;
  background-color: #00a3ff;
  color: #ffffff;
  font-size: 12px;
  font-weight: 600;
  margin-right: 9px;
  letter-spacing: 0;
  line-height: 18px;
  text-align: center;
  padding: 5px 22px;
}

.customer-address__item {
  background-color: #ffffff;
  border: 1px solid #eeeeee;
  border-radius: 6px;
  margin-top: 9px;
  padding: 9px 12px;
  position: relative;
}

.customer-address__edit-icon {
  cursor: pointer;
  fill: #000;
  height: 24px;
  opacity: 0.3;
  position: absolute;
  right: 0;
  top: 0;
  width: 24px;
  z-index: 1;
}

.customer-address__edit-icon:hover {
  opacity: 1;
}

.customer-address__alert {
  align-items: center;
  background: var(--color__red);
  border: 0;
  border-radius: 3px;
  color: var(--white);
  display: flex;
  justify-content: center;
  margin-top: 9px;
  padding: 6px;
  width: 100%;
}

.customer-address__alert-icon {
  fill: var(--white);
  width: 24px;
}

.customer-address__validated {
  align-items: center;
  background: var(--color__green);
  border: 0;
  border-radius: 3px;
  color: var(--white);
  display: flex;
  justify-content: center;
  margin-top: 9px;
  padding: 6px;
  width: 100%;
}

.customer-address__validated-icon {
  fill: var(--white);
  width: 24px;
}

.customer-address__heading {
  opacity: 0.8;
  color: var(--color__black);
  font-size: 14px;
  letter-spacing: 0;
  line-height: 18px;
  margin-bottom: 6px;
}

.customer-address__line {
  color: var(--color__black);
  font-size: 14px;
  letter-spacing: 0;
  line-height: 21px;
}

.grabrail__parts {
  flex-grow: 1;
}

.grabrail__parts-section {
  display: block;
}

.grabrail__parts-section select {
  margin-bottom: 4px;
  width: 100%;
}

.grabrail__parts-label {
  display: block;
  font-size: 14px;
  margin-bottom: 4px;
}

/**/
.shipping-estimate {
  background: #fff;
  border: 1px solid #eeeeee;
  border-radius: 9px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.06);
  padding: 18px;
  position: absolute;
  left: 100px;
  top: 100px;
  width: max-content;
}

.shipping-estimate__header {
  display: flex;
  justify-content: flex-end;
}

.shipping-estimate__content {
  padding: 0 72px 24px;
}

.shipping-estimate__heading {
  margin: 0;
  margin-bottom: 30px;
}

.shipping-estimate__label {
  color: var(--color__black);
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 24px;
  margin-bottom: 15px;
}

.shipping-estimate__box {
  border: 1px solid #eeeeee;
  border-radius: 9px;
  width: 400px;
}

.shipping-estimate__box-row {
  border-bottom: 1px solid #eee;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 18px;
}

.shipping-estimate__box-row:last-child {
  border-bottom: 0;
}

.shipping-estimate__box-label {
  color: var(--color__black);
  font-size: 14px;
  letter-spacing: 0;
  line-height: 18px;
}

.shipping-estimate__box-value {
  color: var(--color__black);
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 18px;
}

.shipping-estimate__contents {
  display: flex;
}

.shipping-estimate__section {
  margin-right: 30px;
}

.shipping-estimate__section:last-child {
  margin-right: 0;
}
